body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgb(25, 25, 25);
  color: white;
}

header {
  padding-top: 20px;
}

.header-link {
  text-decoration: none;
}

.login-btn {
  margin: 2em auto;
}

/* .navbar {
  margin: -20px;
}

.profile-pic {
  border-radius: 50%;
} */

.refresh-playlists-btn {
  margin-bottom: 1em;
}

.refresh-tracks-btn {
  margin: 1em auto;
}

.form-group {
  margin-top: 2em;
  /* margin-right: 30%;
  margin-left: 30%; */
}

#playlist-select {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.playlist-cover {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 2em;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

table {
  text-align: left;
  overflow: scroll;
  /* margin: 20px; */
}

table .note {
  /* background-color: rgb(48, 104, 68); */
  /* background-color: #453C67; */
  background: rgb(69, 60, 103);
  background: linear-gradient(90deg, rgba(69, 60, 103, 1) 17%, rgba(0, 65, 112, 1) 100%);
  padding-top: 1em;
  padding-bottom: 1em;
  white-space: pre-wrap;
}

.artist {
  font-size: 80%;
}

table .number,
table .thumbnail {
  width: 5%;
}

table .title {
  width: 30%
}

table .album {
  width: 35%
}

table .add-edit-note,
table .delete-note {
  width: 12.5%
}

.form-label {
  text-align: left;
  width: 100%;
}

#cancelBtn {
  margin-right: 2%;
}

#noBtn {
  margin-right: 2%;
}

footer {
  margin-top: 50px;
}

.github-btn {
  margin: 3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}